import React, { useEffect, useState } from "react";
import DOMPurify from 'dompurify';
import './Faqs.css';
import axios from "axios";
import { BASE_URL } from "../../constants";
import { FaMinus, FaPlus } from "react-icons/fa";
import DrorLoader from "../charts/drorLoader/DrorLoader";

const isHTML = (str) => {
  const htmlPattern = /<\/?[a-z][\s\S]*>/i;
  return htmlPattern.test(str);
};

const Faqs = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [faqList, setFaqList] = useState([]);
  const [showAnswers, setShowAnswers] = useState([]);

  const handleOpen = () => {
    setOpen(!open);
  }

  const handleShow = (index) => {
    const defaultShow = new Array(faqList?.length).fill(false);
    if (showAnswers[index] === true) {
      console.log('it is true', defaultShow)
      setShowAnswers(defaultShow);
    } else {
      const newShow = [
        ...defaultShow.slice(0, index),
        true,
        ...defaultShow.slice(index + 1)
      ];
      setShowAnswers(newShow);
    }
  }

  const getFaqs = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/support/faqs`);
        setFaqList(response.data.questions);
        setShowAnswers(new Array(response.data.questions?.length).fill(false));
        setLoading(false);
    } catch (error) {
        console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open === true) {
      getFaqs();
    } else {
      setFaqList([]);
      setShowAnswers([]);
      setLoading(true);
    }
  }, [open])

  return (
    <div className="faqs relative inline-block text-left mr-2">
      <div>
        <button
            onClick={handleOpen}
            className="overflow-hidden bg-[#66b8d7] py-1 px-2 rounded text-[#2A2A2A] h-[36px] w-[150px] text-[16px] font-medium text-gray-900 shadow-sm font-bold whitespace-nowrap"
        >
          {/* popular questions */}
          שאלות נפוצות
        </button>
      </div>

      {/*       
        Dropdown menu, show/hide based on menu state.

        Entering: "transition ease-out duration-100"
          From: "transform opacity-0 scale-95"
          To: "transform opacity-100 scale-100"
        Leaving: "transition ease-in duration-75"
          From: "transform opacity-100 scale-100"
          To: "transform opacity-0 scale-95"
      */}

      {
        open ?
        <div className="accordion absolute right-0 z-10 mt-2 w-[350px] origin-top-right rounded bg-[#232323] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {
            loading !== true ?
              <>
                {/* <div className="accordion-item px-4 py-4 mb-2">
                  <a className="accordion-intro-h block text-white">ask free questions and send to brokersconnect</a>
                </div> */}
                {faqList?.map((item, index) => (
                  <div
                    key={index}
                    className="accordion-item px-4 py-4 mb-2"
                    onClick={() => handleShow(index)}
                  >
                    <div className="flex items-center justify-between gap-4">
                      <a className="block text-sm text-white">{item.title}?</a>
                      {showAnswers[index] === false ? (
                        <FaPlus className="accordion-icon" />
                      ) : (
                        <FaMinus className="accordion-icon" />
                      )}
                    </div>
                    {showAnswers[index] === true ? (
                      isHTML(item.answer) ? (
                        // Render as HTML if the answer contains HTML tags
                        <div
                          className="accordion-answer"
                          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.answer) }}
                        />
                      ) : (
                        // Render as plain text if the answer does not contain HTML tags
                        <div className="accordion-answer"><p>{item.answer}</p></div>
                      )
                    ) : null}
                  </div>
                ))}
              </>
              :
              <div className="px-4 py-4 h-[100px]">
                <DrorLoader />
              </div>
          }
        </div>
        :
        null
      }
    </div>

  )
}

export default Faqs;