import React, {useCallback, useEffect, useState} from "react";
import ApexChart from "./horizontalChart";
import ApexBar from "./stackedBarChart/apexBar";
import ApexBar2 from "./stackedBarChart2/apexBar2";
import {FaCaretDown, FaCaretUp, FaVoicemail} from "react-icons/fa";
import {IoStarSharp} from "react-icons/io5";
import DrorLoader from "./drorLoader/DrorLoader";
import {formatDateFromAllQuartersName, convertDateFormat, getQuarterYear, mapEnglishToHebrew} from "./utils";
import Dropdown from "./CustomDropdown";
import { useLayoutEffect } from "react";

// import B from "./chart"
const Charts = ({
                    // Right Chart Data
                    chartType,
                    series,
                    secId,
                    // Searched securities list
                    searchedTerms,
                    // Right Table Data
                    labels_data,
                    // Right Chart Total Bins
                    num,
                    // Right Table click event
                    handleRowClick,
                    // Right table hebrew name
                    allLabelsNames,
                    allQuarterNames,
                    // Left Top chart data
                    bar_monthly_data,
                    bar_monthly_categories,
                    //Right top chart data
                    bar_quarterly_data,
                    bar_quarterly_categories,
                    grandSum,
                    selectedRowId,

                    handleCustomDropdownTop,
                    // dates for checkboxes
                    dates,
                    handleCheckMonth,
                    handleCheckQuarter,
                    checkedValuesMonths,
                    checkedValuesQuarter,
                    valuesForMonthsAndQuarters,
                    handleFilterMonthsAndQuarters,
                    display,
                    legands,
                    num_quarters,
                    loading,
                    loadingleft,
                    // displayleft,
                    handleCustomDropdown,
                    customDropdown,
                    num_multi_search,
                    selected_data_column,
                    rightChartCatagories,
                    secType,
                    handleSelectionChange_,
                    column,
                    keywords,
                    column2,
                    handleSelectionChange2_,
                    activate_star_function,
                    starActive,
                    isCustomFilter,
                    upper_chart_msg,
                    searchedSecName,
                    sec_id_search,
                    setNotification
                }) => {
    const [one, setOne] = React.useState(true);

    const handleStarClick = () => {
        if (starActive === false) {
            setNotification("הני”ע נוסף למועדפים");
        } else {
            setNotification("הני”ע הוסר מהמועדפים")
        }
        activate_star_function(!starActive)
    };

    const [modifiedData, setModifiedData] = useState(labels_data);

    const setFirstToZeroForSumAll = useCallback((data) => {
        // console.log('THE INFORMATION - ', column2, secType, num_multi_search, num_quarters, data)
        if (column2 === 'sectors' || secType[0] === '0' || secType[0] === '15') {
            return data;
        }
        if (num_multi_search > 1 || num_quarters > 1) {
            return labels_data
        }
        const newData = [
            ['sum_all', [grandSum]],
            ...labels_data
        ];
        // console.log('THE NEW DATA - ', newData)
        return newData
    }, [column2, grandSum, labels_data, num_multi_search, num_quarters, secType])

    useEffect(() => {
        setModifiedData(setFirstToZeroForSumAll(labels_data))
    }, [column2, secType, labels_data, num_multi_search, num_quarters, setFirstToZeroForSumAll])



    const isGroupChart = () => {
        // console.log(checkedValuesQuarter.filter(item => item === true).length, num_multi_search, checkedValuesMonths.filter(item => item === true).length)
        if (checkedValuesQuarter.filter(item => item === true)?.length === 0){
            if (checkedValuesMonths.filter(item => item === true)?.length > 1 || num_multi_search > 1){
                return true
            }
        }else if(checkedValuesQuarter.filter(item => item === true)?.length > 1 || num_multi_search > 1){
            return true
        }
        return false
    }

    // const dynamicMarginBottom = () => {
    //     if (checkedValuesQuarter.filter(item => item === true)?.length === 0){
    //         if (checkedValuesMonths.filter(item => item === true)?.length > 1 || num_multi_search > 1){
    //             return checkedValuesMonths.filter(item => item === true)?.length
    //         }
    //     }else if(checkedValuesQuarter.filter(item => item === true)?.length > 1){
    //         return checkedValuesQuarter.filter(item => item === true)?.length
    //     }
    //     return 1
    // }

    const dynamicMargin = () => {
        // console.log('no of multiple - ', num_multi_search, num_quarters)
        let count = 1;
        if (num_multi_search > 1) {
            count = num_multi_search;
        } else if (num_quarters > 1) {
            count = num_quarters
        } else {
            count = 2
        }
        return 10 * (count - 1)
    }


    useLayoutEffect(() => {
    }, [selected_data_column]);

    // Update the Months & Quarters value for filter in real-time
    useEffect(() => {
    }, [valuesForMonthsAndQuarters])

    // Update num for chart height
    useEffect(() => {

    }, [num])

    const getYearWithQuarter = (dateString) => {
        if (!dateString) {
            return ""
        }
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const quarter = Math.ceil(month / 3);
        return `${year} Q${quarter}`;
    }

    const rapnum = (value) => {
        // console.log(value)
        value = parseFloat(value)
        if (value >= 1e9) {
            return (value / 1e9).toFixed(2) + ' B';
        } else if (value >= 1e6) {
            return (value / 1e6).toFixed(2) + ' M';
        } else if (value >= 1e3) {
            return (value / 1e3).toFixed(2) + ' K';
        } else if (value < 1) {
            value = value.toFixed(2)
        } else {
            value = value.toFixed(2)
        }
        return value
    }


    const handleButtonClick = (val, one_two) => {
        if (one_two === '1') {
            setOne(true)
        } else {
            setOne(false)
        }
        console.log(one_two)
        handleSelectionChange_(val, one_two)
    };

    const handleButton2Click = (val) => {
        handleSelectionChange2_(val)
    };

    const checkDisable = (num_multi_search, secType) => {
        if (num_multi_search > 1 || secType[0] === '0' || secType[0] === '15') {
            return true
        }
        return false
    }

    const checkDisableColumn2 = (col) => {
        if (num_multi_search > 1 || secType[0] === '0' || secType[0] === '15' || num_quarters > 1) {
            return "none"
        }
        if (isCustomFilter) {
            if (col === "holders" || col === 'non_holders')
                return "none"
        }
        return 'true'
    }

    const checkVisibleColumn2 = (col) => {
        console.log('isCustomFilter should be false ', isCustomFilter);
        // console.log('num multi search > 1 - ', num_multi_search);
        // console.log('sec type = 0, 15 - ', secType[0]);
        // console.log('num quarters > 1 - ', num_quarters);
        // console.log('col [hol/non_hol] - ', col);
        if (num_multi_search > 1 || secType[0] === '0' || secType[0] === '15' || num_quarters > 1) {
            return "none"
        }
        if (isCustomFilter) {
            if (col === "holders" || col === 'non_holders')
                return "none"
        }
        return "block"
    }

    const checkDisableDropdown = (secType) => {
        // Removed '|| num_quarters > 1' from the end of the if statement below

        // if (num_multi_search > 1 || secType[0] === '0' || secType[0] === '15') {  <=== previous code
        if (secType[0] === '0' || secType[0] === '15') {
            return true
        }
        return false
    }
    const getTableStyle = (num_quarters, val) => {
        return {
            // marginTop: 11,
            // border: "1px solid #1f1f1f",
            border: "none",
            height: 20,
            // height: 17,
            borderCollapse: "collapse"
        };
    };
    const getRowStyle = (val, sec_name) => {
        // console.log('the val - ', val, searchedSecName)
        if (num_quarters > 1) {
            sec_name = ""
        }
        return {
            height: 20,
            backgroundColor: selectedRowId === val?.toString() + sec_name ? 'grey' : allLabelsNames[val] === searchedSecName ? 'gray' : "",
        };
    };
    
    const generateHeight = () => {
        // console.log('THE REAL DEALS - ', num_quarters, secId?.length, searchedTerms?.length, num_multi_search)
        if (num_quarters === 1 && 
            secId?.length === 1 && 
            searchedTerms?.length === 1 &&
            num_multi_search === 1
        ) {
            return num - 117
        }
        if (secId?.length > 1) {
            return num - 118
        }
        if (num_quarters > 1) {
            if (chartType === "f_holder") {
                return num - 118
            }
            return num - 135
        }

        // DEFAULT
        // CUSTOM FILTERS USES THIS DEFAULT
        return num - 117
    }

    return (
        <div className="text-white charts">
            <div className="box-border flex gap-4 justify-between items-start p-4 h-[100%] ">
                <div className="left_chart flex flex-col gap-4 box-border h-[100%] text-black">

                    <div style={{backgroundColor: '#1f1f1f'}} className="w-[100%] flex-1 rounded-[3px] p-4 pb-0 relative">
                        <p style={{
                            marginTop: 135,
                            display: bar_monthly_categories?.length > 0 ? "none" : !upper_chart_msg ? "none" : !loadingleft ? "block" : "none"
                        }} className="text-white text-center absolute w-[100%] font-bold absolute">אין היסטוריה עבור החיפוש
                            המבוקש</p>

                        <ApexBar selected_data_column={selected_data_column} data={bar_monthly_data} categories={bar_monthly_categories} chartType={chartType} />
                        <div>
                            {loadingleft &&
                                // <img height={100} width={100} style={{marginLeft: 380, marginTop: -250}}
                                //                  src="loading1.gif" alt="Loading"/>
                                <div style={{marginTop: -175}}>
                                    <DrorLoader/>
                                </div>
                            }
                        </div>
                    </div>
                    <div style={{backgroundColor: '#1f1f1f'}}
                         className=" w-[100%] flex-1 rounded-[3px] p-4 pb-2 relative">
                        <p style={{
                            marginTop: 135,
                            display: bar_quarterly_categories?.length > 0 ? "none" : !upper_chart_msg ? "none" : !loadingleft ? "block" : "none"
                        }} className="text-white text-center w-[92%] font-bold absolute">אין היסטוריה עבור החיפוש
                            המבוקש</p>

                            <ApexBar2 
                                selected_data_column={selected_data_column} 
                                data={bar_quarterly_data} 
                                categories={bar_quarterly_categories} 
                                chartType={chartType}
                            />
                        <div>
                            {loadingleft &&
                                // <img height={100} width={100} style={{marginLeft: 380, marginTop: -250}}
                                //                  src="loading1.gif" alt="Loading"/>
                                <div style={{marginTop: -195}}>
                                    <DrorLoader/>
                                </div>
                            }
                        </div>
                    </div>


                </div>
                <div style={{backgroundColor: '#1f1f1f'}}
                     className="right_chart box-border h-[100%] overflow-auto rounded-[3px] gap-4 flex justify-between items-start text-white bg-black  p-4">
                    <div className="w-[75%] ">
                        {display ?
                            <div className="relative">
                                {/* MAKE SHIFT LEGEND FOR F_HOLDER CHART */}
                                {chartType === 'f_holder' && series?.length === 1 ? 
                                    <div className="absolute w-[100%] h-[45px] flex items-center justify-center rounded bg-[#84B547]">
                                        <p className="font-semibold">מנהלים זרים</p>
                                    </div>
                                    :null
                                }
                                {
                                    chartType === 'quarterly' ?
                                    (secType[0] === '0' || secType[0] === '15' ?

                                        <div className="flex justify-end items-center py-2 options">
                                            {/* COMMENTED OUT THIS FOR SECTOR 2 FOR LATER IN THE FUTURE */}
                                            {/* <p
                                                style={
                                                    {
                                                        fontWeight: column === "control_corp" ? "bold" : "normal",
                                                        color: column === "control_corp" ? "#ffffff" : "#ffffff80"
                                                    }
                                                }
                                                onClick={() => handleButtonClick("control_corp", '1')}
                                                className="cursor-pointer ml-4 pb-2 text-[#ffffff80] hover:text-[#ffffff]">
                                                אחזקה מצרפית
                                            </p> */}
                                            {
                                                keywords[0].slice(0, 3) === "ענף" ?
                                                    <>
                                                         <p onClick={() => handleButtonClick("sec_id", '2')}
                                                           style={
                                                               {
                                                                   fontWeight: column === "sec_id" && !one ? "bold" : "normal",
                                                                   color: column === "sec_id" && !one ? "#ffffff" : "#ffffff80"
                                                               }
                                                           }
                                                           className="cursor-pointer ml-4 pb-2 text-[#ffffff80] hover:text-[#ffffff] ">

                                                            {/*רשימת ני”ע 2*/}
                                                            אג”ח
                                                        </p>
                                                        <p onClick={() => handleButtonClick("sec_id", '1')}
                                                           style={
                                                               {
                                                                   fontWeight: column === "sec_id" && one ? "bold" : "normal",
                                                                   color: column === "sec_id" && one ? "#ffffff" : "#ffffff80"
                                                               }
                                                           }
                                                           className="cursor-pointer ml-4 pb-2 text-[#ffffff80] hover:text-[#ffffff] ">

                                                            {/*רשימת ני”ע 1*/}
                                                            מניות
                                                        </p>

                                                    </>
                                                    :
                                                    <p onClick={() => handleButtonClick("sec_id", '1')}
                                                       style={
                                                           {
                                                               fontWeight: column === "sec_id" ? "bold" : "normal",
                                                               color: column === "sec_id" ? "#ffffff" : "#ffffff80"
                                                           }
                                                       }
                                                       className="cursor-pointer ml-4 pb-2 text-[#ffffff80] hover:text-[#ffffff] ">

                                                        רשימת ני”ע
                                                    </p>
                                            }

                                        </div>
                                        :
                                        <div className="flex justify-end items-center py-2 options">


                                            <p
                                                style={
                                                    {

                                                        display: checkVisibleColumn2("holders_similar"),
                                                        fontWeight: column2 === "holders_similar" ? "bold" : "normal",
                                                        color: column2 === "holders_similar" ? "#ffffff" : "#ffffff80",
                                                        pointerEvents: checkDisableColumn2("holders_similar")
                                                    }
                                                }
                                                onClick={() => handleButton2Click("holders_similar",)}
                                                className="cursor-pointer ml-4 pb-2 text-[#ffffff80] hover:text-[#ffffff]">
                                                אחזקות בני”ע דומים
                                            </p>
                                            <p
                                                style={
                                                    {
                                                        display: checkVisibleColumn2('sectors'),
                                                        fontWeight: column2 === "sectors" ? "bold" : "normal",
                                                        color: column2 === "sectors" ? "#ffffff" : "#ffffff80",
                                                        pointerEvents: checkDisableColumn2('sectors')
                                                    }
                                                }
                                                onClick={() => handleButton2Click("sectors")}
                                                className="cursor-pointer ml-4 pb-2 text-[#ffffff80] hover:text-[#ffffff]">
                                                רשימת ני”ע דומים
                                                {/*sectors*/}
                                            </p>

                                            <p
                                                style={
                                                    {
                                                        display: checkVisibleColumn2("non_holders"),
                                                        fontWeight: column2 === "non_holders" ? "bold" : "normal",
                                                        color: column2 === "non_holders" ? "#ffffff" : "#ffffff80",
                                                        pointerEvents: checkDisableColumn2("non_holders")
                                                    }
                                                }
                                                onClick={() => handleButton2Click("non_holders",)}
                                                className="cursor-pointer ml-4 pb-2 text-[#ffffff80] hover:text-[#ffffff]">
                                                שאינם מחזיקים
                                            </p>
                                            <p
                                                style={
                                                    {
                                                        display: checkVisibleColumn2("holders"),
                                                        fontWeight: column2 === "holders" ? "bold" : "normal",
                                                        color: column2 === "holders" ? "#ffffff" : "#ffffff80",
                                                        pointerEvents: checkDisableColumn2("non_holders")
                                                    }
                                                }
                                                onClick={() => handleButton2Click("holders",)}
                                                className="cursor-pointer ml-4 pb-2 text-[#ffffff80] hover:text-[#ffffff]">
                                                אחזקה מצרפית
                                            </p>
                                        </div>
                                    ):
                                    null
                                }
                                {/* {
                                    console.log('IMPORTANT DATA - ', 
                                        '\n\n series, num, legands, data_column, rightchartcat, num_multi_search \n\n',
                                        series, 
                                        num, 
                                        legands, 
                                        selected_data_column,
                                        rightChartCatagories,
                                        num_multi_search)
                                } */}
                                <ApexChart
                                    data={series}
                                    numBins={num}
                                    legands={legands}
                                    rightChartCatagories={rightChartCatagories}
                                    selected_data_column={selected_data_column}
                                    num_quarters={num_quarters}
                                    num_multi_search={num_multi_search}
                                    chartType={chartType}
                                    column2={column2}
                                    secType={secType}
                                />
                                
                                {/* IF MANAGER RESULT IS EMPTY */}
                                { rightChartCatagories?.length === 0 ? 
                                    // <p className="absolute text-right top-[120px] w-[100%] p-[20px]">אין תוצאות עבור החיפוש המבוקש</p> :
                                    <p className="absolute text-right top-[120px] w-[100%] p-[20px]">לא קיימים מנהלים אשר אינם מחזיקים בני”ע ומחזיקים בני”ע דומים</p> :
                                    null
                                }
                            </div> :
                            <div>{loading &&
                                <div style={{marginTop: 320, marginLeft: 200}}>
                                    <DrorLoader/>
                                </div>
                            }</div>
                        }

                    </div>

                    <div className="vals w-[25%] ">
                        <div className="header relative flex justify-between items-center">
                            <Dropdown 
                                chartType={chartType}
                                num_multi_search={num_multi_search}
                                grandSum={grandSum}
                                selected_data_column={selected_data_column}
                                secType={secType}
                                checkDisableDropdown={checkDisableDropdown}
                                handleCustomDropdownTop={handleCustomDropdownTop}
                            />
                            <div
                                onClick={handleStarClick}
                                className="h-[45px] rounded bg-[#292E32] ml-2  min-w-[38px] flex justify-center items-center"
                            >
                                <IoStarSharp
                                    className={`m-auto text-2xl  ${
                                        starActive ? "text-[#F7B84B]" : "text-[#7F7F7F]"
                                    }`}
                                />
                            </div>
                        </div>
                        {display ?
                            <>
                                <div
                                    id="mydiv"
                                    // style={{marginBottom: (num_multi_search > 1 ? num_multi_search : dynamicMarginBottom()) * 7}}
                                    style={{marginBottom: dynamicMargin()}}
                                    // style={{marginBottom: 8}}
                                    className="bg-white py-0 h-[25px] mt-2 flex justify-between items-center relative">
                                    <div className="p-0 bg-transparent w-auto">
                                        {chartType !== 'continuous' ? 
                                        <>
                                            <p
                                                onClick={handleCustomDropdown}
                                                tabIndex={0}
                                                className="btn pl-3 bg-transparent hover:bg-transparent text-black border-0"
                                            >
                                                {customDropdown ? <FaCaretUp/> : <FaCaretDown/>}
                                            </p>
                                            {customDropdown && !isCustomFilter && !(column2 === "sectors" || secType[0] === "0" || secType[0] === "15") && (
                                                <div className="custom-dropdown bg-white p-2 rounded">
                                                    <div className={`${chartType === "quarterly" && "flex"} gap-1 mb-0`}>
                                                        {/* IF CHART TYPE = QUARTERLY, display month checkboxes*/}
                                                        {chartType === 'quarterly' ? 
                                                            <div dir="rtl" className="flex-1 float-right">
                                                                <label style={{fontSize: 11}}
                                                                    className="text-black font-bold text-right">&nbsp;&nbsp;&nbsp;קרנות
                                                                    בלבד </label>
                                                                {/*<label className="text-black">Months</label>*/}
                                                                {dates[chartType]['months'].map((item, index) => (
                                                                    <div key={index}
                                                                        className="flex mb-3"
                                                                    >

                                                                        <input
                                                                            value={index}
                                                                            type="checkbox"
                                                                            checked={checkedValuesMonths[index]}
                                                                            onChange={handleCheckMonth}
                                                                            className="mr-1"
                                                                            disabled={checkDisable(num_multi_search, secType)}
                                                                        />

                                                                        <label className="text-black text-sm">
                                                                            &nbsp;{mapEnglishToHebrew(convertDateFormat(item[0]).split(" ")[0]) + " " + convertDateFormat(item[0]).split(" ")[1]}
                                                                        </label>

                                                                    </div>
                                                                ))}
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                        <div dir="rtl" className="float-right">
                                                            <label style={{fontSize: 11}}
                                                                className="text-black font-bold"> כל הקופות
                                                                והקרנות</label>
                                                            {/*<label className="text-black">Quarters</label>*/}
                                                            {dates[chartType]['quarters'].map((item, index) => (
                                                                <div key={index}
                                                                    className="flex mb-3 ">

                                                                    <input
                                                                        value={index}
                                                                        type="checkbox"
                                                                        checked={checkedValuesQuarter[index]}
                                                                        onChange={handleCheckQuarter}
                                                                        className="mr-1"
                                                                        disabled={checkDisable(num_multi_search, secType)}
                                                                    />
                                                                    <label className="text-black text-sm">
                                                                        &nbsp;{getQuarterYear(item[0])}
                                                                    </label>

                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <p dir="rtl" className="text-black text-sm mb-2">ניתן לבחור עד 5 רבעונים להשוואה</p>
                                                    <button 
                                                        onClick={handleFilterMonthsAndQuarters}
                                                        disabled={
                                                            ((valuesForMonthsAndQuarters[0] + valuesForMonthsAndQuarters[1] >= 2) &&
                                                            (valuesForMonthsAndQuarters[0] + valuesForMonthsAndQuarters[1] <= 5)) ?
                                                            false : 
                                                            true
                                                        }
                                                        className="btn btn-sm text-center ml-[auto] mb-1 rounded-[4px]">
                                                            שלח
                                                    </button>
                                                </div>
                                            )}
                                        </>  :
                                        null
                                        }
                                        {/* ) : chartType === 'f_holder' ? (
                                            null
                                        ) : null} */}
                                    </div>
                                    <p className="text-xs text-black p-2 font-semibold">
                                        {(() => {
                                            if (chartType === 'quarterly' || chartType === 'f_holder') {
                                                return getYearWithQuarter(dates[chartType]['quarters'][0]);
                                            } else if (chartType === 'continuous') {
                                                return "עדכון רציף";
                                            }
                                            return null; // or any other default/fallback value
                                        })()}
                                    </p>
                                </div>

                                {/* SUM ALL LINK FOR LEFT CHART */}
                                {/* <table
                                    className="w-full cursor-pointer"
                                    // style={getTableStyle(num_quarters, row[0])}
                                    // key={ind}
                                >
                                    <tbody>
                                        <tr
                                            style={{
                                                height: 20,
                                                color: "",
                                            }}
                                            className=" w-full"
                                            onClick={() => handleRowClick(labels_data[0], 0, "sum")}
                                        >
                                            <td className="w-[25%] border-none p-0 font-bold whitespace-nowrap">
                                                10.2BB
                                            </td>
                                            <td dir="rtl" className="border-none p-0 text-white w-[70%] text-xs font-black text-right whitespace-nowrap" colSpan={3}>
                                                <span>Sum all</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table> */}

                                <div className="flex flex-col justify-between" style={{height: generateHeight(), justifyContent: `${modifiedData?.length === 1 ? 'center' : modifiedData?.length === 2 ? 'space-around' : 'space-between'}`}}>
                                {modifiedData?.map((row, ind) => (
                                    <table
                                        className="w-full "
                                        style={getTableStyle(num_quarters, row[0])}
                                        key={ind}
                                    >
                                        <tbody>
                                        { isGroupChart()?
                                            <tr
                                                style={{
                                                    height: 20,
                                                    // height: 17,
                                                    color: "",
                                                }}
                                                className=" w-full "
                                            >
                                                {/* THE HEADER OF THE GROUP CHART (in white background) */}
                                                <td dir="rtl" className="border-none text-black text-xs font-black text-right "
                                                    title={allLabelsNames[row[0]]}
                                                    colSpan={3}>
                                                  <p
                                                      className="truncate overflow-hidden w-[min-content] max-w-[200px]"
                                                      style={{
                                                          backgroundColor: "white",
                                                      }}
                                                  >
                                                    {row[0] === 'sum_all' ? null : allLabelsNames[row[0]].substring(0, 33)}
                                                    {/* {allLabelsNames[row[0]].substring(0, 33)} */}
                                                  </p>
                                                </td>
                                            </tr> : null
                                        }
                                        {
                                            column2 === "sectors" || secType[0] === "0" || secType[0] === "15" ?
                                            <tr
                                                style={getRowStyle(row[0], allQuarterNames[0])}
                                                className=" w-full table_row"
                                                // onClick={() => handleRowClick(row, 0)}
                                            >
                                                {/* DISPLAYS THE SUM ALL IF COLUMN IS SECTOR & INDEX IS 0 */}
                                                <td className="w-[25%] border-none p-0 font-bold whitespace-nowrap" onClick={() => handleRowClick(row, 0)}>
                                                    {rapnum(row[1][0]).toString()}{selected_data_column === "sum_par_percentage" ? " %" : ""}
                                                </td>

                                                {isGroupChart() ?
                                                    <td dir="rtl" className="border-none p-0 text-right font-black w-[70%] "
                                                        onClick={() => handleRowClick(row, 0)}
                                                        title= {
                                                            searchedTerms?.length > 1 ? 
                                                            [...searchedTerms]?.reverse()[0][0] : valuesForMonthsAndQuarters[0] <= 1 ? 
                                                            allQuarterNames[0] : formatDateFromAllQuartersName(allQuarterNames[0])
                                                        }
                                                    >
                                                        {
                                                            searchedTerms?.length > 1 ? 
                                                            [...searchedTerms]?.reverse()[0][0] : valuesForMonthsAndQuarters[0] <= 1 ? 
                                                            allQuarterNames[0] : formatDateFromAllQuartersName(allQuarterNames[0])
                                                        }
                                                    </td>
                                                    :
                                                    <td dir="rtl"
                                                        className="border-none p-0 text-white w-[70%] text-xs font-black text-right whitespace-nowrap "
                                                        title={allLabelsNames[row[0]]}
                                                        colSpan={3}
                                                    >
                                                        {/* DISPLAYS THE SUM ALL IF COLUMN IS NOT SECTOR & INDEX IS 0 */}
                                                        <span onClick={() => handleRowClick(row, 0)}>{allLabelsNames[row[0]]?.substring(0, 20)}</span>
                                                        {
                                                            // column2 === "sectors" ?
                                                            (column2 === "sectors" || secType[0] === "0" || secType[0] === "15") ?
                                                                <div title="פתח בחיפוש חדש"
                                                                    className="flex  w-[35px] items-center justify-center text-right float-left cursor-pointer"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        sec_id_search(allLabelsNames[row[0]]);
                                                                    }}
                                                                >
                                                                    {/* {"(---)"} */}
                                                                    <FaVoicemail />
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                    </td>
                                                }
                                            </tr>
                                            :
                                            <tr
                                                style={getRowStyle(row[0], allQuarterNames[0])}
                                                className=" w-full table_row"
                                                // onClick={() => handleRowClick(row, 0)}
                                            >
                                                
                                                <td className="w-[25%] border-none p-0 font-bold whitespace-nowrap" onClick={() => handleRowClick(row, 0)}>
                                                    {rapnum(row[1][0]).toString()}{selected_data_column === "sum_par_percentage" ? " %" : ""}
                                                </td>

                                                {isGroupChart() ?
                                                    <td dir="rtl" className="border-none p-0 text-right font-black w-[70%] "
                                                        onClick={() => handleRowClick(row, 0)}
                                                        title= {
                                                            searchedTerms?.length > 1 ? 
                                                            [...searchedTerms]?.reverse()[0][0] : valuesForMonthsAndQuarters[0] <= 1 ? 
                                                            allQuarterNames[0] : formatDateFromAllQuartersName(allQuarterNames[0])
                                                        }
                                                    >
                                                        {
                                                            searchedTerms?.length > 1 ? 
                                                            [...searchedTerms]?.reverse()[0][0] : valuesForMonthsAndQuarters[0] <= 1 ? 
                                                            allQuarterNames[0] : formatDateFromAllQuartersName(allQuarterNames[0])
                                                        }
                                                    </td>
                                                    :
                                                    <td dir="rtl"
                                                        className="border-none p-0 text-white w-[70%] text-xs font-black text-right whitespace-nowrap "
                                                        title={allLabelsNames[row[0]]}
                                                        colSpan={3}
                                                    >
                                                        {/* DISPLAYS THE SUM ALL IF COLUMN IS NOT SECTOR & INDEX IS 0 */}
                                                        {(column2 === "holders" || column2 === "holders_similar" || column2 === "non_holders") && modifiedData[0] === row ?
                                                            <span onClick={() => handleRowClick(row, 0)}>סה"כ</span> :
                                                            <span onClick={() => handleRowClick(row, 0)}>{allLabelsNames[row[0]]?.substring(0, 20)}</span>
                                                        }
                                                    </td>
                                                }
                                            </tr>
                                        }

                                        {row[1].slice(1).map((value, index) => (
                                            <tr
                                                style={getRowStyle(row[0], allQuarterNames[index + 1])}
                                                className="w-full table_row"
                                                key={index}
                                                onClick={() => {handleRowClick(row, index + 1)}}
                                                    // onClick={() => {console.log('THE IND 2-' , searchedTerms, rapnum(row[1][index+1]), getSearchTermIndex(searchedTerms, ind))}}
                                            >
                                                <td className="border-none p-0 font-bold">
                                                    {rapnum(value)} {selected_data_column === "sum_par_percentage" ? " %" : ""}
                                                </td>
                                                <td dir="rtl" className="border-none p-0 text-right font-black whitespace-nowrap"
                                                    // title={valuesForMonthsAndQuarters[1] <= 1 && searchedTerms?.length <= 1 ? allQuarterNames[0] : formatDateFromAllQuartersName(allQuarterNames[index + 1])}>
                                                    // {valuesForMonthsAndQuarters[1] <= 1 && searchedTerms?.length <= 1 ? allQuarterNames[0] : formatDateFromAllQuartersName(allQuarterNames[index + 1].substring(0, 27))}
                                                    title= {
                                                        searchedTerms?.length > 1 ? 
                                                        [...searchedTerms]?.reverse()[index+1][0] : valuesForMonthsAndQuarters[1] <= 1 ? 
                                                        allQuarterNames[0] : formatDateFromAllQuartersName(allQuarterNames[index + 1])
                                                    }
                                                >
                                                    {
                                                        searchedTerms?.length > 1 ? 
                                                        [...searchedTerms]?.reverse()[index+1][0] : valuesForMonthsAndQuarters[1] <= 1 ? 
                                                        allQuarterNames[0] : formatDateFromAllQuartersName(allQuarterNames[index + 1])
                                                    }
                                                </td>

                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                ))}
                                </div>
                            </>
                            : null
                        }
                    </div>
                </div>

            </div>
        </div>
    )
        ;
};

export default Charts;


// FOR THE NEW QUERY
// LOOK AT LINES 254, 258, 377