import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import axios from 'axios';
// import Cookies from 'js-cookie';
import Modal from './Modal';
import Swal from "sweetalert2";  // Adjust the path accordingly.
import DrorLoader from '../../charts/drorLoader/DrorLoader';

const LoginPage = ({handleLogin}) => {
    const [isMobile, setIsMobile] = useState(false);
    const [isSubscriberScreen, setSubscriberScreen] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const [forgotPassword, setForgotPassword] = useState(false);
    const [resetEmail, setResetEmail] = useState('');
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [errorType, setErrorType] = useState('');
    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);

    const handleModalSubmit = async (data) => {
         try {
            const response = await axios.post('/accounts/licence-activation/', data);
            if (response.status === 200) {
            } else  {
                    Swal.fire({
                    title: "הטופס נשלח בהצלחה",
                    text: "אנו ניצור איתך קשר באמצעות דואר אלקטרוני או טלפון בהקדם,",
                    icon: 'info',
                    // confirmButtonText: 'Close'
                    confirmButtonText: 'Close'
                })
            }
        } catch (error) {
            console.error("There was an error submitting the form data:", error);
        }
    };
    const handleUsernameChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleForgotPassword = () => {
        setForgotPassword(true);
    };

    const handleResetEmailChange = (e) => {
        setResetEmail(e.target.value);
        setError("")
    };

    const handleSendResetEmail = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Email validation regex (basic pattern matching for an email)
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (!emailRegex.test(resetEmail)) {
            setError('Please enter a valid email address.');
            return;
        }

        // Additional validations can go here...
        try {
            await axios.post('/accounts/forgot_password/', {
                email: resetEmail,
            });
            Swal.fire({
                title: "איפוס סיסמה",
                text: "לינק לאיפוס הסיסמה נשלח למייל שהזנת",
                icon: 'success',
                // confirmButtonText: 'Close'
                confirmButtonText: 'סגור'
            }).then((result) => {
                if (result.isConfirmed) {
                    setPageLoading(true);
                    window.location.href = '/';
                }
            });
        } catch (error) {
            Swal.fire({
                title: "איפוס סיסמה",
                text: "אירעה שגיאה",
                icon: 'error',
                // confirmButtonText: 'Close'
                confirmButtonText: 'סגור'
            })
        }

        setLoading(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Validate inputs
        if (!email || !password) {
            setError('Both email and password are required.');
            setLoading(false);
            return;
        }

        // Email validation regex (basic pattern matching for an email)
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (!emailRegex.test(email)) {
            setError('Please enter a valid email address.');
            setLoading(false);
            return;
        }

        // IF LOGIN IS FROM MOBILE
        if (isMobile) {
            setShowModal(true);
            setLoading(false);
            return;
        }
        
        try {
            const response = await axios.post('/accounts/login/', {
                email: email,
                password: password
            });

            if (response.status === 200) {
                const data = response.data;

                sessionStorage.setItem('email', email);
                sessionStorage.setItem('refreshToken', data.refresh);
                sessionStorage.setItem('accessToken', data.access);

                // Route to home after successful registration
                navigate('/');
                handleLogin()
                setError('');
                setErrorType('');
            } else {
                setError('שם משתמש או סיסמה אינם נכונים');
                setErrorType('');
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setError(error.response.data.detail);
                setErrorType('');

            } else if (error.response && error.response.status === 403) {  // 403 is typically "Forbidden"
                setSubscriberScreen(true);
                setShowModal(true);
                setErrorType(error?.response?.data);
            } else {
                setError('חלה תקלה בנסיון ההתחברות, אנא נסה שוב מאוחר יותר');
                setErrorType('');
            }
        }
        setLoading(false);
    };
    
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1024); // Adjust the width as needed
        };
    
        // Initial check
        handleResize();
    
        // Add event listener for window resize
        window.addEventListener('resize', handleResize);
    
        // Clean up event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div dir="rtl" className="flex h-[97vh] items-center justify-center px-4">
            {pageLoading ? <DrorLoader /> :
            <>
                <form style={{backgroundColor: '#1f1f1f', maxWidth: 450, fontFamily: "open sans hebrew"}}
                    className="p-8 w-[100%] shadow-md rounded-md rounded-xl text-white" onSubmit={handleSubmit}>
                    <h2 className="text-2xl text-center font-bold mb-6">התחבר</h2>
                    {forgotPassword ? (
                        <>
                            <p className="mb-4">שכחת את הסיסמה?</p>
                            <p className="mb-4">הזן את כתובת המייל באמצעותה נרשמת לאתר ואנו נשלח לך מייל מאובטח לשינוי
                                הסיסמה</p>
                            <div className="mb-4">
                                <label htmlFor="reset-email" className="block text-sm font-bold mb-2">
                                    מייל
                                </label>
                                <input
                                    type="email"
                                    id="reset-email"
                                    className="text_box_color w-full p-2  rounded-md "
                                    value={resetEmail}
                                    onChange={handleResetEmailChange}
                                    required
                                    onInvalid={(e) => e.currentTarget.setCustomValidity('כתובת המייל אינה תקינה')}

                                />
                            </div>
                            {error && <p className="text-white mb-4">{error}</p>}
                            <button
                                type="button"
                                onClick={handleSendResetEmail}
                                className="w-full orange_color text-white p-2 rounded-md hover:bg-orange-600"
                            >
                                שלח
                            </button>
                        </>
                    ) : (
                        <>
                            <div className="mb-4">
                                <label htmlFor="username" className="block text-sm font-bold mb-2">
                                    מייל
                                </label>
                                <input
                                    type="email"
                                    id="username"
                                    className="w-full p-2  rounded-md text_box_color"
                                    value={email}
                                    onChange={handleUsernameChange}
                                    required
                                    onInvalid={(e) => e.currentTarget.setCustomValidity('כתובת המייל אינה תקינה')}
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="password" className="block  text-sm font-bold mb-2">
                                    סיסמה
                                </label>
                                <div className="relative">
                                    <input
                                        type={showPassword ? 'text' : 'password'}
                                        id="password"
                                        className="w-full p-2  rounded-md text_box_color"
                                        value={password}
                                        onChange={handlePasswordChange}
                                        required
                                    />
                                    <button
                                        type="button"
                                        className="absolute top-2 left-4 text-gray-500"
                                        onClick={handleTogglePasswordVisibility}
                                    >
                                        {showPassword ? 'הסתר' : 'הצג'}
                                    </button>
                                </div>
                            </div>
                            <div className="orange_font mb-2 text-sm cursor-pointer" onClick={handleForgotPassword}>
                                שכחת את הסיסמה?
                            </div>
                            {error && <p className="text-white mb-4">{error}</p>}
                            <button
                                disabled={loading}
                                type="submit"
                                className="w-full orange_color text-white p-2 rounded-md hover:bg-orange-600"
                            >
                                התחבר
                            </button>
                        </>
                    )}
                    <p className="mt-4">
                        אין לך עדיין חשבון?
                        <Link to={'/signup'} className="orange_font"> הרשם</Link>
                    </p>
                    <p className="mt-2">
                        <Link to={'/policy'} className="orange_font">
                            מדיניות האתר
                        </Link>
                    </p>
                </form>
                <Modal
                    show={showModal}
                    onClose={() => setShowModal(false)}
                    onSubmit={handleModalSubmit}
                    errorType={errorType}
                    isMobile={isMobile}
                    isSubscriberScreen={isSubscriberScreen}
                />
            </>
            }
        </div>

    );
};

export default LoginPage;
