import React, { useEffect, useState } from "react";
import {Link, useNavigate} from 'react-router-dom';
import DOMPurify from 'dompurify';
import './Contact.css';
import axios from "axios";
import { BASE_URL } from "../../constants";
import { FaMinus, FaPlus } from "react-icons/fa";
import DrorLoader from "../charts/drorLoader/DrorLoader";
import Swal from "sweetalert2";
import Cookies from 'js-cookie';

const isHTML = (str) => {
  const htmlPattern = /<\/?[a-z][\s\S]*>/i;
  return htmlPattern.test(str);
};

const Contact = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contactData, setContactData] = useState({
    email: "",
    // name: "",
    // company: "",
    message: ""
  })

  const handleOpen = () => {
    setOpen(!open);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactData({
      ...contactData,
      [name]: value
    })
  };

  const handleSubmit = async (e) => { 
    setLoading(true);
    try {
      const response = await axios.post('/support/contact/', {
        ...contactData,
        email: sessionStorage.getItem('email'),
      });

      // Close Modal after message sent successfully
      setOpen(false);
      setContactData({
        email: "",
        // name: "",
        // company: "",
        message: ""
      })
      Swal.fire({
          title: "ההודעה נשלחה",
          // text: "נחזור אליך בהקדם האפשרי",
          icon: 'success',
          // confirmButtonText: 'Close'
          confirmButtonText: 'סגור'
      })
    } catch (error) {
      Swal.fire({
          title: "שגיאה",
          // text: "הייתה בעיה בשליחת ההודעה שלך",
          icon: 'error',
          // confirmButtonText: 'Close'
          confirmButtonText: 'סגור'
      })
    }
    setLoading(false);
  };


  useEffect(() => {
  }, [])

  return (
    <div className="contact relative inline-block text-right w-[100px] mr-4">
      <div>
        <a
            onClick={handleOpen}
            className="text-[#FFFFFF] h-[36px] w-[150px] text-[16px] font-medium font-bold whitespace-nowrap cursor-pointer"
        >
          {/* Contact */}
          כתוב לנו
        </a>
      </div>

      {
        open ?
        <div style={{zIndex: 999}} className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-90">
          <div className="accordion rounded-[10px] right-0 z-10 mt-2 w-[350px] origin-top-right bg-[#232323] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {
              loading !== true ?
                <>
                  {/* <div className="accordion-item px-4 py-4 mb-2">
                    <a className="accordion-intro-h block text-white">ask free questions and send to brokersconnect</a>
                  </div> */}
                  
                  <div 
                    style={{fontFamily: "open sans hebrew"}}
                    className="py-8 px-6 w-[100%] text-white" onSubmit={handleSubmit}
                  >
                    <h2 className="text-xl text-right font-bold mb-6">כתוב לנו</h2>
        
                    {/* EMAIL */}
                    <div className="mb-6">
                        {/* <label htmlFor="username" className="block text-sm font-bold mb-2">
                            מייל
                        </label> */}
                        {/* <input
                          dir="rtl"
                          type="email"
                          name="email"
                          className="w-full p-2  rounded-md text_box_color"
                          value={contactData.email}
                          onChange={handleChange}
                          placeholder="מייל"
                          required
                        /> */}
                    </div>

                    {/* NAME */}
                    <div className="mb-6">
                        {/* <label htmlFor="username" className="block text-sm font-bold mb-2">
                          שם
                        </label> */}
                        {/* <input
                          dir="rtl"
                          type="text"
                          name="name"
                          className="w-full p-2  rounded-md text_box_color"
                          value={contactData.name}
                          onChange={handleChange}
                          placeholder="שם"
                          required
                        /> */}
                    </div>

                    <div className="mb-6">
                        {/* <label htmlFor="username" className="block text-sm font-bold mb-2">
                          חברה
                        </label> */}
                        {/* <input
                          dir="rtl"
                          type="text"
                          name="company"
                          className="w-full p-2  rounded-md text_box_color"
                          value={contactData.company}
                          onChange={handleChange}
                          placeholder="חברה"
                          required
                        /> */}
                    </div>
                    
                    <div className="mb-6">
                        {/* <label htmlFor="username" className="block text-sm font-bold mb-2">
                          הודעה
                        </label> */}
                        <textarea
                          dir="rtl"
                          type="text"
                          name="message"
                          className="w-full p-2  rounded-md text_box_color"
                          value={contactData.message}
                          onChange={handleChange}
                          placeholder="הודעה"
                          required
                          rows={10}
                        />
                    </div>
                    
                    {/* {error && <p className="text-white mb-4">{error}</p>} */}
                    <button
                        disabled={loading}
                        type="submit"
                        className="w-full bg-[#66b8d7] text-white p-2 rounded-md hover:bg-[#66b8d7]"
                        onClick={handleSubmit}
                    >
                          שלח
                    </button>
                    <button
                        className="w-full mt-4 orange_color text-white p-2 rounded-md hover:orange_color"
                        onClick={() => {setOpen(false)}}
                    >
                          סגור
                    </button>
                  </div>
                </>
                :
                <div className="px-4 py-4 h-[100px]">
                  <DrorLoader />
                </div>
            }
          </div>
        </div>
        :
        null
      }
    </div>

  )
}

export default Contact;