export function convertDateFormat(inputDate) {
    if (!(inputDate && inputDate.length === 10)) {
        return "";
    } else {
        const parts = inputDate.split('-');
        const year = parseInt(parts[0]);
        const month = parseInt(parts[1]);
        const currentDate = new Date(year, month - 1);
        const options = {year: '2-digit', month: 'short'};
        return currentDate.toLocaleDateString('en-US', options);
    }

}

export function mapEnglishToHebrew(englishText) {
    const dictionary = {
        "provident": "גמל",
        "pension": "פנסיה",
        "insurance": "ביטוח",
        "mutual funds": "קרנות נאמנות",
        "mutual fund etf": "קרנות מחקות וסל",
        "jan" :"ינו",
        "feb" :"פבר",
        "mar" :"מרץ",
        "apr" :"אפר",
        "may" :"מאי",
        "jun" :"יונ",
        "jul" :"יול",
        "aug" :"אוג",
        "sep" :"ספט",
        "oct" :"אוק",
        "nov" :"נוב",
        "dec" :"דצמ",

        "f_holder": "אחזקות זרים",
        'fund_1_continuous': "גמל ביטוח פנסיה",
        'fund_3_continuous': "קרנות נאמנות, סל ומחקות",
        'fund_4_7_continuous': "אחר",

        "sum_market_cap": "שווי שוק",
        "sum_par_value": "ערך נקוב",
        "sum_par_percentage": "אחוז מהון"
    };

    // Convert to lowercase for case-insensitive mapping


    // Check if the English text exists in the dictionary
    if (englishText && dictionary.hasOwnProperty(englishText.toString().toLowerCase())) {
        return dictionary[englishText.toString().toLowerCase()];
    } else {
        return englishText;
    }
}

export function getQuarterYear(inputDate) {
    if (!(inputDate && inputDate.length === 10)) {
        return inputDate;
    } else {
        const [year, month, day] = inputDate.split('-').map(Number);
        const quarter = Math.floor((month - 1) / 3) + 1;
        return `${year} Q${quarter}`;
    }
}

export function formatDateFromAllQuartersName(dateStr) {
    if (dateStr.length === 0) { return dateStr }
    if (!isInExpectedFormat(dateStr)) { return dateStr }
    
    const monthMap = {
        M1 :"ינו",
        M2 :"פבר",
        M3 :"מרץ",
        M4 :"אפר",
        M5 :"מאי",
        M6 :"יונ",
        M7 :"יול",
        M8 :"אוג",
        M9 :"ספט",
        M10 :"אוק",
        M11 :"נוב",
        M12 :"דצמ",
    };

    const [year, monthCode] = dateStr.split(' ');
    // If it is in Quarter format [Q2 2024], not month format [M3 2022]
    if (monthCode?.toLowerCase()?.startsWith('q')) {
        return dateStr
    }

    const monthName = monthMap[monthCode];

    // Return formatted month name and last 2 digit of the year
    return `${monthName} ${year.slice(-2)}`;

    function isInExpectedFormat(str) {
        // Regular expression to match the format 'M<number> <year>'
        const regex = /^M(1[0-2]|[1-9]) \d{4}$/;
        return !regex.test(str);
    }
}

export function convertDateFormat2(inputDate) {
    if (!inputDate) { return "" }
    // Convert the string to a Date object
    const date = new Date(inputDate);

    // Extract the day, month, and year
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(-2);

    // Return the date formatted as DD/MM/YY
    return `${day}/${month}/${year}`;
}