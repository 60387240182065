import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import axios from 'axios';
import Search from './components/search';
import TopBar from './components/topbar';
import Login from './components/pages/login/login';
import SignUp from './components/pages/signup/signup';
import Holdings from "./components/holdings";
import Policy from './components/pages/policy/policy';
import ResetPasswordPage from './components/pages/login/ResetPassword';
import ActivationPage from './components/pages/login/ActivationPage.js';

function App() {
    const [isMobile, setIsMobile] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [activeComponent, setActiveComponent] = useState('search');

    useEffect(() => {
        const token = sessionStorage.getItem('accessToken');
        if (token) {
            setIsLoggedIn(true);
        }
    }, [isLoggedIn]);

    const handleLogin = () => {
        setIsLoggedIn(true);
        setActiveComponent('search');
    };

    const handleLogout = async () => {
        try {
            const accessToken = sessionStorage.getItem('accessToken'); // or localStorage.getItem('accessToken');
            if (accessToken) {
                await axios.post('/accounts/logout/', {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    }
                });
                // Clear out authentication-related cookies or local storage items
                sessionStorage.clear()
                // Cookies.remove('accessToken');
                // Cookies.remove('refreshToken');
                // Cookies.remove('email');
            // ... remove other cookies or local storage items if necessary
                console.log('No access token found');
            }
    
            setActiveComponent('login');
            setIsLoggedIn(false);

        } catch (err) {
            console.log('Error logging out:', err);
        }
    };
      
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1024); // Adjust the width as needed
        };
    
        // Initial check
        handleResize();
    
        // Add event listener for window resize
        window.addEventListener('resize', handleResize);
    
        // Clean up event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);
      
    if (isMobile && isLoggedIn) {
        handleLogout();
    }
    
    return (
        <Router>
            <div className="App">
                <TopBar
                    isLoggedIn={isLoggedIn}
                    onSearchClick={() => setActiveComponent('search')}
                    onLoginClick={() => setActiveComponent('login')}
                    onLogoutClick={handleLogout}
                />
                <main className="main grid grid-cols-1 grid-rows-[30px,80px,auto]">
                    <Routes>
                        <Route
                            path="/policy"
                            element={<Policy />}
                        />
                        <Route
                            path="/reset-password/:uidb64/:token"
                            element={<ResetPasswordPage />}
                        />
                        <Route
                            path="/activate/:uidb64/:token"
                            element={<ActivationPage />}
                        />

                        <Route
                            path="/"
                            element={isLoggedIn ? (
                                <>
                                    <Holdings/>
                                    <Search handle_logout={handleLogout}/>
                                </>
                            ) : (
                                <Navigate to="/login"/>
                            )}
                        />

                        {/*<Route*/}
                        {/*    path="/"*/}
                        {/*    element={(*/}
                        {/*        <>*/}
                        {/*            <Holdings/>*/}
                        {/*            <Search/>*/}
                        {/*        </>*/}
                        {/*    )}*/}
                        {/*/>*/}

                        <Route
                            path="/login"
                            element={
                                !isLoggedIn ? (
                                    <Login handleLogin={handleLogin}/>
                                ) : (
                                    <Navigate to="/"/>
                                )}
                        />
                        
                        <Route
                            path="/signup"
                            element={
                                !isLoggedIn ? (
                                    <SignUp handleLogin={handleLogin}/>
                                ) : (
                                    <Navigate to="/search"/>
                                )}
                        />
                        <Route
                            path="*"
                            element={<Navigate to="/login"/>}
                        />
                    </Routes>
                </main>
            </div>
        </Router>
    );
    
}


export default App;
